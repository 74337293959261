import * as React from 'react'
import {Helmet} from 'react-helmet'
import {Container} from 'reactstrap'
import {PassengerDetailsForm} from '../../components/ClaimForm/PassengerDetailsForm'
import {ClaimFormState} from '../../components/ClaimForm/state'
import {Provider, observer} from 'mobx-react'
import Layout from '../../components/Layout/Layout'

import './claim-form.scss'
import DebugStepLinks from '../../components/ClaimForm/DebugStepLinks'


interface Props {
    location: Location
}

@observer
export default class ClaimFormPassengerPage extends React.Component<Props> {
    render () {
        return <Layout className="claim-form-page">
            <Container>
                <Helmet>
                    <meta name="robots" content="noindex"/>
                </Helmet>

                <div className="row mb-5">
                    <div className="offset-md-2 col-md-8">
                        <PassengerDetailsForm location={this.props.location}/>
                    </div>
                </div>

            </Container>

            <DebugStepLinks/>

        </Layout>

    }
}
