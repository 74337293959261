import * as React from 'react'
import { t } from 'ttag'
import {observer} from 'mobx-react'
import {action} from 'mobx'
import {FormGroup, Label, Input, Row, Col} from 'reactstrap'
import {Upload, UppyWrapper} from '../Forms/Upload'
import {FieldState} from 'formstate'
import {FieldFeedBack} from '../Forms/FieldFeedBack'
import {PassengerFormState, IDDocumentFormState} from './state'

interface PassengerUploadsProps {
    passenger: PassengerFormState
    passengerNr: number
    extra?: boolean
    className?: string
    makeIDDocumentFormState: () => IDDocumentFormState
}

@observer
export default class PassengerUploads extends React.Component<PassengerUploadsProps> {
    
    onIsPassportSelectedChange(value: boolean) {
        if (value) {
            this.props.passenger.$.idDoc.$.splice(1)
        } else {
            this.props.passenger.$.idDoc.$.splice(
                this.props.passenger.$.idDoc.$.length - 1, 0, this.props.makeIDDocumentFormState()
            )  
        }

        this.props.passenger.$.isPassportSelected.onChange(value)
    }

    onUploadSuccess = (field: FieldState<Upload>, file: Upload) => {
        field.onChange(file)
        field.validate()
    }

    render () {
        const fields = this.props.passenger.$
        const radioName = `is_passport_selected_${this.props.passengerNr}`

        return <Row className={this.props.className}>
            <Col md={12}>
                <FormGroup>
                    <Label>{t`Boarding pass or ticket`}</Label>

                    { fields.boardingPassDoc.value ?
                        <div key={fields.boardingPassDoc.value.id}>{fields.boardingPassDoc.value.name}</div>
                        :
                        null
                    }

                    <UppyWrapper onUploadSuccess={(file) => this.onUploadSuccess(fields.boardingPassDoc, file)}/>

                    <FieldFeedBack field={fields.boardingPassDoc}/>
                </FormGroup>
            </Col>

            <Col md={12}>
                <FormGroup>
                    <Label>{t`What document would you like to provide?`}</Label>

                    <FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name={radioName}
                                    value={'aye'}
                                    checked={this.props.passenger.$.isPassportSelected.value}
                                    onChange={action(() => this.onIsPassportSelectedChange(true))}
                                />
                                {' '} {t`Passport`}
                            </Label>
                        </FormGroup>

                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name={radioName}
                                    value={''}
                                    checked={!this.props.passenger.$.isPassportSelected.value}
                                    onChange={action(() => this.onIsPassportSelectedChange(false))}
                                />
                                {' '} {t`ID card`}
                            </Label>
                        </FormGroup>
                    </FormGroup>

                    { fields.idDoc.$.length > 1 ?
                        <Row>
                            <Col md={12}>
                                {t`Photos of both the front and the back of the card are required:`}
                            </Col>
                        </Row> : null
                    }

                    <Row>
                        {
                            fields.idDoc.$.map((document, key) => {
                                return <Col key={key} md={fields.idDoc.$.length > 1 ? 6 : 12}>
                                    {
                                        document.$.idDocSide.value ?
                                        <div key={document.$.idDocSide.value.id}>
                                            {document.$.idDocSide.value.name}
                                        </div>
                                        :
                                        null
                                    }
                                    <UppyWrapper
                                        onUploadSuccess={(file) => this.onUploadSuccess(
                                            document.$.idDocSide, file
                                        )}
                                    />
                                    <FieldFeedBack field={document.$.idDocSide}/>
                                </Col>
                            })
                        }
                    </Row>
                </FormGroup>
            </Col>
        </Row>
    }
}
