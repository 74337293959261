import * as React from 'react'
import {action} from 'mobx'
import {observer} from 'mobx-react'
import { t } from 'ttag'
import autobind from 'autobind-decorator'
import scrollIntoView from 'scroll-into-view-if-needed'
import {Spinner} from 'react-activity'
import {Form, FormGroup, Label, Input, Button, Alert} from 'reactstrap'
import {getStateInstance, PassengerFormState, IDDocumentFormState} from './state'
import {FieldFeedBack} from '../Forms/FieldFeedBack'
import PassengerUploads from './PassengerUploads'
import { navigate } from '../../util/navigate'
import {getLocale} from '../../locales'


interface ExtraPassengerFormProps {
    removePassenger: () => void,
    makeIDDocumentFormState: () => IDDocumentFormState
    formState: PassengerFormState,
    passengerNr: number,
}

@observer
class ExtraPassengerForm extends React.Component<ExtraPassengerFormProps> {
    render () {
        let fields = this.props.formState.$
        let paxNr = Number(this.props.passengerNr)

        return <div className="extra-passenger">
            <h3 className="mt-4 mb-3">
                {t`Additional passenger #${paxNr} details`}
                <Button className="float-end" size="sm" onClick={this.props.removePassenger}>
                    {t`Remove passenger`}
                </Button>
            </h3>

            <FormGroup>
                <Label>{t`Full name`}</Label>
                <Input
                    type="text"
                    placeholder={t`e.g. Alex Traveller`}
                    value={fields.name.value || ''}
                    invalid={!!fields.name.error}
                    onChange={(e) => fields.name.onChange(e.target.value)}
                    onBlur={(e) => fields.name.validate()}
                />
                <FieldFeedBack field={fields.name}/>
            </FormGroup>

            <FormGroup>
                <Label for="email">{t`E-mail address (optional)`}</Label>
                <Input
                    type="email"
                    name="email"
                    invalid={!!fields.email.error}
                    value={fields.email.value || ''}
                    onChange={(e) => fields.email.onChange(e.target.value)}
                    onBlur={() => fields.email.validate()}
                />
                <FieldFeedBack field={fields.email}/>
            </FormGroup>

            <FormGroup>
                <Label for="email">{t`Phone number (optional)`}</Label>
                <Input
                    type="text"
                    name="phoneNumber"
                    invalid={!!fields.phoneNumber.error}
                    value={fields.phoneNumber.value || ''}
                    onChange={(e) => fields.phoneNumber.onChange(e.target.value)}
                    onBlur={() => fields.phoneNumber.validate()}
                />
                <FieldFeedBack field={fields.phoneNumber}/>
            </FormGroup>

            <PassengerUploads
                makeIDDocumentFormState={() => this.props.makeIDDocumentFormState()}
                passenger={this.props.formState}
                passengerNr={this.props.passengerNr}
                extra={true}
            />

            <p className="mt-3">
                {t`Is the passenger a child?`}
            </p>

            <FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="radio"
                            name={`is_child_${paxNr}`}
                            checked={fields.isChild.value}
                            onChange={action(() => fields.isChild.onChange(true))}
                        />
                        {' '} {t`Yes`}
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="radio"
                            name={`is_child_${paxNr}`}
                            checked={!fields.isChild.value}
                            onChange={action(() => fields.isChild.onChange(false))}
                        />
                        {' '} {t`No`}
                    </Label>
                </FormGroup>
            </FormGroup>
        </div>
    }
}


interface PassengerDetailsFormProps {
    location: Location,
}

@observer
export class PassengerDetailsForm extends React.Component<PassengerDetailsFormProps> {

    render () {
        let pageState = getStateInstance()

        return <Form onSubmit={this.onSubmit}>
            <h1>{t`Other passengers`}</h1>
            <p>
                {t`
                    If you were travelling with family, friends or colleagues, you can claim
                    compensation for them as well.
                `}
            </p>

            <p>
                {t`Please select:`}
            </p>

            <FormGroup check>
                <Label check>
                    <Input
                        type="radio"
                        name="payment_status"
                        value={''}
                        checked={!pageState.hasExtraPassengers}
                        onChange={action(() => pageState.hasExtraPassengers = false)}
                    />
                    {' '} {t`I was travelling alone`}
                </Label>
            </FormGroup>

            <FormGroup check>
                <Label check>
                    <Input
                        type="radio"
                        name="payment_status"
                        value={'aye'}
                        checked={pageState.hasExtraPassengers}
                        onChange={action(() => pageState.hasExtraPassengers = true)}
                    />
                    {' '}
                    {t`
                        I was travelling with a group and would like to claim compensation
                        for other passengers in the group
                    `}
                </Label>
            </FormGroup>

            {this.renderExtraPassengers()}

            <div className="mt-5 mb-3">
                {t`By continuing you indicate agreement with the following terms:`}
                <ol>
                    <li className="mt-4 mb-3">
                        {t`No fees or charges apply if compensation cannot be claimed by Refundor`}
                    </li>

                    <li className="mt-3">
                        {t`
                            Refundor retains 25% from any successfully claimed compensation and
                            25€ per passenger as service fee
                        `}
                    </li>
                </ol>
            </div>

            <p className="mt-3">
                {t`And:`}
            </p>

            <FormGroup check>
                <Label check>
                    <Input 
                        type="radio"
                        name="embedded_signature"
                        value={'aye'}
                        checked={pageState.useEmbeddedSignature}
                        onChange={action(() => pageState.useEmbeddedSignature = true)}
                    />
                    {' '} {t`I will sign the necessary claim documents now`}
                </Label>
            </FormGroup>

            <FormGroup check>
                <Label check>
                    <Input 
                        type="radio"
                        name="embedded_signature"
                        value={''}
                        checked={!pageState.useEmbeddedSignature}
                        onChange={action(() => pageState.useEmbeddedSignature = false)}
                    />
                    {' '} {t`I will sign the claim documents later`}
                </Label>
            </FormGroup>

            {
                pageState.globalErrorMessage ?
                    <Alert color="error">{pageState.globalErrorMessage}</Alert>
                    :
                    null
            }

            <Button
                disabled={pageState.submitting || !!pageState.lastClaimNumber}
                color="primary"
                block
                className="submit mt-4">

                { pageState.submitting ?
                    [
                        <Spinner
                            key="spinner"
                            color="white"
                            style={{display: "inline-block", marginBottom: "-0.5rem", marginRight: "0.5rem"}}
                        />,
                        <span key="label">{t`Preparing claim documents`}</span>
                    ]
                    :
                    t`Claim compensation`
                }
            </Button>

        </Form>
    }


    @autobind
    async onSubmit (e) {
        e.preventDefault()

        let pageState = getStateInstance()

        let valid = true
        if (pageState.hasExtraPassengers) {
            let validationResult = await pageState.formState.passenger.validate()
            if (validationResult.hasError) {
                valid = false
            }
        }

        if (valid) {
            let submitResult = await pageState.submitForm()
            if (submitResult) {
                pageState.clearForm()
                navigate('/claim-form/claim-registered', getLocale(this.props.location).shortCode)
            }
        }
    }

    renderExtraPassengers () {
        let pageState = getStateInstance()

        if (!pageState.hasExtraPassengers) return

        return pageState.formState.passenger.$.map(
            (f, i) => <ExtraPassengerForm
                key={i}
                passengerNr={i+1}
                removePassenger={() => pageState.removeExtraPassenger(i)}
                makeIDDocumentFormState={() => pageState.makeIDDocumentFormState()}
                formState={f}
            />
        ).concat([
            <Button key="add-button" size="sm" onClick={pageState.addExtraPassenger}>
                {t`Add another passenger`}
            </Button>
        ])
    }
}
